import {Component, inject} from '@angular/core';
import {BaseComponent} from '../../../core/base.component';
import {EntityType} from '../../../libs/preference.enums';
import {PortfolioEditorService} from './portfolio-editor.service';
import {EntityEditorService} from '../../../shared/entity-editor/entity-editor.service';
import {EntityAction} from "../../../shared/entity-editor";

@Component({
  selector: 'eclipse-portfolio-editor-notes',
  templateUrl: './portfolio-notes.component.html'
})
export class PortfolioNotesComponent extends BaseComponent {

  public readonly portfolioEditorService: PortfolioEditorService = inject(PortfolioEditorService);
  private readonly _entityEditorService: EntityEditorService = inject(EntityEditorService);

  entityType: EntityType = EntityType.Portfolio;

  constructor() {
    super();
  }

  onNoteChanged() {
    this.portfolioEditorService.refreshNotes();
    this._entityEditorService.entityChangedMessage({
      relatedType: this.entityType,
      relatedId: this.portfolioEditorService.portfolioId$.value,
      entityAction: EntityAction.Notes
    });
  }
}
