import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { INote, IRelatedEntity } from '../../models/notes';
import { BaseComponent } from '../../core/base.component';
import { EntityType } from '../../libs/preference.enums';
import { NotesService } from '../../services/notes.service';
import { NoteEditorComponent } from './';
import { forkJoin, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import * as Consts from "../../libs/app.constants";
import { Table } from "primeng/table";
import { Utils as Util } from "../../core/functions";
import { PreferenceService } from "../../services/preference.service";
import { EntityAction, IEntityMessage } from "../../shared/entity-editor";

@Component({
  selector: 'eclipse-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent extends BaseComponent {
  @ViewChild('noteEditor') noteEditor: NoteEditorComponent;
  @Input() entityId: number;
  @Input() entityType: EntityType;
  @ViewChild('dt') dt: Table;
  @ViewChild('noteSummaryTemplate') noteSummaryTemplate: TemplateRef<any>;
  @Output() onNoteTouched: EventEmitter<IEntityMessage> = new EventEmitter<IEntityMessage>();

  noteDialog = false; // Show the Note Editor dialog
  notesActiveFilter: 'Active' | 'All' = 'Active'; // Filter to show only Active notes or All notes
  filteredNotes: INote[] = []; // Notes after the selected filter have been applied
  teamNotes: INote[] = []; // Team-level notes
  selectedNote: INote; // the note selected by the user
  canReadNotes: boolean = false; // Can the user read notes at any level
  canAddNotes: boolean = false; // Can the user add notes at any level
  // entities related to the current entity.  Ex. if current entity is a portfolio, then relatedEntities has the portfolio,
  // all accounts assigned to the portfolio, and the portfolios primary team.
  relatedEntities: IRelatedEntity[];
  contextMenuItems: MenuItem[]; // Context menu items for the selected note
  loading: boolean = false; // Flag indicating the entity's notes are being loaded.
  dataSubscription: Subscription; // Subscription for the entity note load
  showOCNoteLegend = false;
  entityTypes = EntityType;
  displayNoteDefault: boolean = true;

  private _notes: INote[] = [];
  // All notes for the current entity (and related entities)
  public get notes(): INote[] {
    return this._notes;
  }
  public set notes(value: INote[]) {
    this._notes = value;
    this.setFilteredNotes();
  }

  public get showTeamNotesInList(): boolean {
    return this.entityType === EntityType.Team;
  }

  constructor(private readonly _notesService: NotesService, private  readonly _preferenceService: PreferenceService) {
    super();
    this.canAddNotes = this._notesService.canUserAddNotes();
    this.canReadNotes = !!this._sessionHelper.getPermission(Consts.PRIV_NOTES)?.canRead;
  }

  /**
   * Load the notes after the component and child components have rendered in case the entityId/entityType were bound.
   */
  ngAfterViewInit() {
    this.getNotes();
  }

  ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
  }

  contextMenuSelectionChange(note) {
    this.setContextMenuItems(note);
  }

  setContextMenuItems(note: INote): void {
    // Don't provide context menu actions if the user cannot edit the note.
    this.contextMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-faw fa-pencil',
        visible: note.canEdit,
        command: () => this.editNote(note),
      },
      {
        label: 'Archive',
        icon: 'fas fa-faw fa-box-archive',
        visible: note.canEdit && (!note.endDate || note.endDate > new Date()),
        command: () => this.archiveNote(note),
      },
      {
        label: 'Delete',
        icon: 'fa fa-fw fa-trash',
        visible: note.canDelete,
        command: () => this.deleteNote(note),
      },
    ];
  }

  changeActiveFilter() {
    this.setFilteredNotes();
  }

  /**
   * Filters the notes that are displayed in the table.
   */
  setFilteredNotes() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    let filtered = this.notes;
    this.teamNotes = [];

    // If team notes are hidden, remove them from the initial list and add them to the team notes list
    if (!this.showTeamNotesInList) {
      // Copy active team notes to a separate list
      this.teamNotes = filtered?.filter(n => n.relatedType === EntityType.Team
      && ((n.endDate !== null && n.startDate <= now && n.endDate >= now)
          || (n.endDate === null && n.startDate <= now)));
      filtered = filtered?.filter(n => n.relatedType !== EntityType.Team);
    }

    this.filteredNotes = filtered?.filter(n => this.notesActiveFilter === 'All'
      || ((n.endDate !== null && n.startDate <= now && n.endDate >= now)
        || (n.endDate === null && n.startDate <= now))) || [];
  }

  hideDialog() {
    this.noteDialog = false;
    this.dataSubscription?.unsubscribe();
  }

  /**
   * Creates a new note and opens the note editor with it.
   */
  addNewNote() {
    const firmId = this._sessionHelper.getUser().firmId;
    this.displayNoteDefault = this._sessionHelper.get(`displayNoteDefault_${firmId}`);

    if(this.displayNoteDefault == null) {
      this.displayNoteDefault = true;
      this._preferenceService.getPreferenceByName('displayNoteDefault').subscribe(result => {
        if(result) {
          this.displayNoteDefault = Util.convertIntoBooleanValue(result);
        }
        this._sessionHelper.set(`displayNoteDefault_${firmId}`, this.displayNoteDefault);
        this.noteDialog = true;
        this.noteEditor.addNewNote(this.displayNoteDefault);
      });
    }
    else {
      this.noteDialog = true;
      this.noteEditor.addNewNote(this.displayNoteDefault);
    }
  }

  /**
   * Opens the note editor with an existing note.
   * @param noteToEdit
   */
  editNote(noteToEdit: INote) {
    this.noteDialog = true;
    this.noteEditor.editNote(noteToEdit);
  }

  /**
   * Archives a note.  Sets the end date to "yesterday" so it drops off the Active list.
   * @param noteToArchive
   */
  archiveNote(noteToArchive: INote): void {
    const entityName = noteToArchive.entityName; // backup the notes entity name
    const yesterday = new Date();
    yesterday.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1); // create a 'yesterday' date

    // Notes with future start dates will have the start date set to yesterday, otherwise it would
    // show as an active note on that future date.
    if (noteToArchive.startDate > new Date()) {
      noteToArchive.startDate = yesterday;
    }

    // set the end date to the start date or yesterday, whichever is later.
    noteToArchive.endDate = noteToArchive.startDate > yesterday ? noteToArchive.startDate : yesterday;

    // update the note with the new dates
    this._notesService.updateNotes([noteToArchive])
      .pipe(tap(notes => {
        notes.forEach(note => {
          // Entity name (ex. "Default Team") is not handled by CRUD methods, so we have to set it ourselves based on the note.
          // Since archiving only sets the end date, we can just re-use the name on the note.
          note.entityName = entityName;
          // Process the note to set the entity levels, format dates, etc.
          this._notesService.processNote(note, this._sessionHelper.getUser(), this.entityId, this.entityType);
        });
      }))
      .subscribe((notes: INote[]) => {
        this.onNoteChanged(notes);
      });
  }

  /**
   * Deletes a note from the list.
   * @param noteToDelete
   */
  deleteNote(noteToDelete: INote): void {
    this._notesService.deleteNotes([noteToDelete.id])
      .subscribe(() => {
        const notes = this.notes.filter(note => note.id !== noteToDelete.id);
        this.notes = notes;
        this.onNoteTouched.emit({
          relatedType: noteToDelete.relatedType,
          relatedId: noteToDelete.relatedId,
          entityAction: EntityAction.Notes
        });
      });
  }

  /**
   * Loads the notes for an entity.
   * @param entity Optional, provides a method for loading notes without binding to entityId and entityType.
   */
  getNotes(entity?: { entityId: number; entityType: EntityType }) {
    this.dataSubscription?.unsubscribe();
    this.notes = [];

    if(!this.canReadNotes) {
      return;
    }

    // if an entity is provided via the params rather than via binding, load the params entity.
    if (entity?.entityId && entity?.entityType) {
      this.entityId = entity.entityId;
      this.entityType = entity.entityType;
    }

    if (!this.entityId || !this.entityType) {
      return;
    }

    this.loading = true;
    // load the notes and the related entities
    this.dataSubscription = forkJoin([this._notesService.getNotesByEntity(this.entityId, this.entityType),
      this._notesService.getRelatedEntities(this.entityId, this.entityType)])
      .subscribe(([responseNotes, responseRelatedEntities]: [INote[], IRelatedEntity[]]) => {
        responseNotes = this.sortNotes(responseNotes);
        this.notes = responseNotes;
        this.showOCNoteLegend = this.notes?.some(n => !!n.orionConnectNote);
        this.relatedEntities = responseRelatedEntities;
        this.loading = false;
      });
  }

  /**
   * Sorts notes.  Newest start date comes first.
   * @param notesToSort
   */
  sortNotes(notesToSort: INote[]): INote[] {
    return notesToSort.sort((a, b) => {
      if (a.startDate > b.startDate) {
        return -1;
      } else if (a.startDate < b.startDate) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Note editor has added/changed a note.
   * If the note did not exist before, it's added to the top of the array.
   * If a note already existed, update it in the list.
   * @param changedNotes
   */
  onNoteChanged(changedNotes: INote[]): void {
    let newNotes = Object.assign([], this.notes);
    changedNotes.forEach(changedNote => {
      const existingNote = newNotes.find(n => n.id === changedNote.id);
      if (!!existingNote) {
        newNotes = newNotes.map(note => note.id !== changedNote.id ? note : changedNote);
      } else {
        newNotes.unshift(changedNote); // New note was added, push it on top
      }
      newNotes = this.sortNotes(newNotes);
    });
    this.notes = newNotes;
    this.noteDialog = false;
    if (!!changedNotes && changedNotes.length > 0) {
      this.onNoteTouched.emit({
        relatedType: changedNotes[0].relatedType,
        relatedId: changedNotes[0].relatedId,
        entityAction: EntityAction.Notes
      });
    }
  }
}
