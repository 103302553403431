import {Component, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {
  INotificationModel,
  INotificationVM,
  IProgressNotification,
  ProgressNotificationStatus,
} from '../../models/notification';
import {BaseComponent} from '../../core/base.component';
import {ConfigService} from '../../config/config.service';
import * as Consts from '../../libs/app.constants';
import {interval} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import { AuthService } from 'src/app/core';
import { api } from '../../../environments/api';

@Component({
  selector: 'eclipse-notification-monitor',
  templateUrl: './notification-monitor.component.html'
})
export class NotificationMonitorComponent extends BaseComponent {
  socket: any;
  messages: any = [];
  ids: any = [];
  connection;
  message: string;
  results: any;
  msgCount = 0;
  analyticsCount = 0;
  @Output() msgCounter = new EventEmitter();
  @Output() analyticNeedCounter = new EventEmitter();
  @Output() onNotificationComplete = new EventEmitter();
  errorMessage: string;
  notification = <INotificationModel>{};
  notificationVM = <INotificationVM>{};
  severity: any[] = [];
  navigateURL: string;
  queryParams: any;
  clickHere: string;
  totalCount: any;

  /******* PROGRESS BAR VARIABLES **/
  value: number = 0;
  analyticsProgress: string;
  showProgressBar: boolean = false;
  progressNotificationsList: IProgressNotification[] = [];
  public intervalSubscribe: any = null;

  constructor(private readonly _router: Router, private readonly _toastr: ToastrService, private readonly notificationService: NotificationService, private readonly _authService: AuthService) {
    super();
  }

  ngOnInit() {
    if (this.connection && !this.connection.closed) {
      this.connection.unsubscribe();
    }
    this.notificationService.ConnectSocket(api.notificationEndpoint, this._authService.getToken());
    this.notificationsCounter(); // get unread counter to show in bell icon using emit
    this.analyticCounter();
    this.loadNotifications(); // notification subscription
    this.getSeverityIcons();
    this.setTimeIntervalForProgressNotifications();   // Handling Reset analytics from UI
  }

  ngOnDestroy() {
    this.notificationService.DisconnectSocket();
    if (this.intervalSubscribe && !this.intervalSubscribe.closed) {
      this.intervalSubscribe.unsubscribe();
    }
  }

  // This method is used to load Notifications
  loadNotifications() {
    this.connection = this.notificationService.getNotifications().subscribe(notification => {
      this.notificationVM = JSON.parse(<string>notification);
      switch (+this.notificationVM.typeId) {
        case Consts.EmitNotificationType.NotificationWindow: /** FOR NOTIFICATION WINDOW MESSAGE/S */
          if (!!this.notificationVM.userNotification) {
            const severity = this.severity.find(s => s.severityType === this.notificationVM.severityType); // This "severity" variable is used to fetch the severity type and get that respective class from environment
            if (!!severity) {
              this.createHtmlNotification(this.notificationVM.userNotification.notificationCategory.name, this.notificationVM.userNotification.id, this.notificationVM.userNotification.subject, this.notificationVM.userNotification.body, 5000, severity.icon, this.notificationVM.userNotification.subCode);
              this.msgCount++;
            }
          }
          break;
        case Consts.EmitNotificationType.ProgressWindow: /**FOR PROGRESS WINDOW NOTIFICATION */
          if (this.notificationVM.code !== 'PARTIALANALYSIS' && this.notificationVM.code !== 'FULLANALYSIS') {
            this.showProgressBar = true;
            if (this.notificationVM.progressNotification) {
              this.addOrUpdateProgressNotificationsList(this.notificationVM.progressNotification);
              this.showProgress(this.notificationVM.progressNotification);
            }
            this.notificationService.pushProgressNotification(this.notificationVM);
          }
          break;
        case Consts.EmitNotificationType.MenuNotification:
          const user = this._sessionHelper.getUser();
          if (this.notificationVM.firmId === user.firmId) {
            if (this.notificationVM.code === 'AnalyticsNeedCount') {
              this.analyticCounter(); // Show Analytics Need Count according to user id.
              this.notificationService.getNeedAnalyticsStatus(this.notificationVM);
              if (this.notificationVM.lastImportedDate) {
                this.notificationService.getLastImportDate({lastImportedDate: this.notificationVM.lastImportedDate});
              }
            }
            if (this.notificationVM.code === 'AnalyticsDuration') {
              this.notificationService.getAnalyticsDuration(this.notificationVM);
            }

            if (this.notificationVM.code === Consts.NOTIFICATION_CATEGORY.ANALYTICS_PORTFOLIO_STATUS) {
              this.notificationService.analyticsPortfolioStatus.next(this.notificationVM);
            }
          }

          if (this.notificationVM.menuNotification) {
            this.notificationService.publishNotification(this.notificationVM.menuNotification);
          }
          break;

        case Consts.EmitNotificationType.TradeToolProgressNotification:
          this.showProgressBar = true;
          // Multiple time same progress percentage emit which causing the inconsistent behavior of progress bar
          const firmId = this._sessionHelper.getUser().firmId;
          let progressPercentage = this._sessionHelper.get(`progress_${firmId}`);
          progressPercentage = progressPercentage ? progressPercentage : 0;
          if (this.notificationVM.tradeToolProgressNotification.progress > progressPercentage) {
            if (this.notificationVM.tradeToolProgressNotification) {
              const tradeToolProgreesNotification = <IProgressNotification>{
                message: this.notificationVM.tradeToolProgressNotification.message,
                processId: this.notificationVM.tradeToolProgressNotification.processId,
                status: this.notificationVM.tradeToolProgressNotification.status,
                progress: this.notificationVM.tradeToolProgressNotification.progress
              };
              this.addOrUpdateProgressNotificationsList(tradeToolProgreesNotification);

              this.showProgress(tradeToolProgreesNotification);
            }
            /* Need to push tradetool (rebalancer) notifications*/
            this._sessionHelper.set(`progress_${firmId}`, this.notificationVM.tradeToolProgressNotification.progress);
            if (!!this.notificationVM.code && (this.notificationVM.tradeToolProgressNotification !== null || this.notificationVM.tradeToolProgressNotification !== undefined)) {
              if (this.notificationVM.tradeToolProgressNotification.progress === 100) {
                if (this.notificationVM.code.toLocaleLowerCase() === 'tradegen') {
                  this.notificationService.pushTradeToolNotification(this.notificationVM);
                }
                this._sessionHelper.set(`progress_${firmId}`, 0);
              }
            }
          }
          break;
        case Consts.EmitNotificationType.TradeFileNotification:
          /*
              SF-146676 Added the trade file notification when ever the trade file status complete.
              This notification will receive to open the trade file without refreshing browser from trade file tab.
          */
          this.notificationService.onTradeFileNotificationComplete(this.notificationVM);
          break;
        /*Changes for OE-2706 added new case to verify trade process notification */
        case Consts.EmitNotificationType.TradeProcessNotification:
          if (this.notificationVM.code === 'TradeProcessNotification') {
            this.notificationService.publishNotification(this.notificationVM);
          }
          break;
        case Consts.EmitNotificationType.MFBatchExecutionNotification:
          this.notificationService.requestToUpdateBatchPercent(this.notificationVM.fixMFBatchExecutionNotification);
          break;
        case Consts.EmitNotificationType.ModelUpdateNotification:
          this.notificationService.modelUpdateNotification(this.notificationVM.modelUpdateNotification);
          break;
        case Consts.EmitNotificationType.BlockUpdateNotification:
          this.notificationService.onBlockUpdateNotification(this.notificationVM.userNotification);
          break;
      }
    });
  }

  showProgress(progressNotification: IProgressNotification) {
    this.value = progressNotification.progress;
    this.analyticsProgress = progressNotification.message;
    if (progressNotification.progress !== 100) {
      this.showProgressBar = true;
    } else {
      const existNotification = this.progressNotificationsList.find(item => item.processId === progressNotification.processId);
      const index = this.progressNotificationsList.indexOf(existNotification);
      this.progressNotificationsList.splice(index, 1);
      if (!this.progressNotificationsList.length) {
        this.showProgressBar = false;
      } else {
        this.showProgressBar = true;
        this.showProgress(this.progressNotificationsList[0]);
      }
    }
  }

  notificationsCounter() {
    this.notificationService.getAllNotificationsCount()
      .subscribe((msg: INotificationVM) => {
        this.msgCount = msg[0].TotalNotificationCount - msg[0].ReadNotificationCount;
        this.msgCounter.emit(this.msgCount);
      });
  }

  analyticCounter() {
    this.notificationService.getAnalyticsNeedCount()
      .subscribe((msg: INotificationVM) => {
        this.analyticsCount = msg.analyticCount;
        this.notificationService.getNeedAnalyticsCount(this.analyticsCount);
      });
  }

  routeLink() {
    if (this.queryParams) {
      this._router.navigate([this.navigateURL], {queryParams: this.queryParams});
    } else {
      this._router.navigate([this.navigateURL]);
    }
  }

  // Uncomment to see click function in notification toaster
  createHtmlNotification(notificationCategoryName: string, id: number, body: string, subject: string, timeOut: number, severityType: string, subCode: string) {
    // Setting descriptive link here
    let recordId = null;
    if (subCode === Consts.NotificationSubType.ImportComplete) {
      recordId = id;
    }
    this.clickHere = this.clickHere ? this.clickHere : '';
    this.setNavigationLink(this.notificationVM.userNotification.subCode, recordId);
    if (notificationCategoryName === 'Trading' || notificationCategoryName === 'Approval' || notificationCategoryName === 'System Status') {
      this.notifyImport();
      const notification = this._toastr.show(`<div class="toaster"><h5><i class="far ${severityType} toaster-type} aria-hidden="true"></i>${body}</h5><p><div class="notification-cat">${subject}<div></p><p><a>${this.clickHere}</a></p></div>`, null, {
        timeOut: timeOut,
        tapToDismiss: false,
        enableHtml: true,
        messageClass: ''
      });
      notification.onTap.pipe(take(1)).subscribe(item => this.routeLink());
    } else {
      this.notifyImport();
      this._toastr.show(`<div class="toaster"><h5><i class="fas fa-chart-area text-info toaster-icon" aria-hidden="true"></i><p class="toaster-body">${body}</p><i class="far ${severityType} toaster-type" aria-hidden="true"></i></h5><p><div class="notification-cat">${subject}<div></p></div>`, null, {
        timeOut: timeOut,
        tapToDismiss: false,
        enableHtml: true,
        messageClass: ''
      });
    }
  }

  notifyImport() {
    if (this.notificationVM.userNotification.subCode === Consts.NotificationSubType.ImportComplete) {
      if (this.notificationVM.progressNotification.progress === 100) {
        this.notificationService.onNotificationComplete();
      }
    }
  }

  /**Adding or Updating progrss notifications */
  addOrUpdateProgressNotificationsList(notification: IProgressNotification) {
    notification.receivedOn = new Date();
    const existNotification = this.progressNotificationsList.find(t => t.processId === notification.processId);
    if (!existNotification) {
      this.progressNotificationsList.push(notification);
    } else {
      this.progressNotificationsList.forEach(item => {
        if (item.processId === notification.processId) {
          const index = this.progressNotificationsList.indexOf(item);
          this.progressNotificationsList[index] = notification;
        }
      });
    }
  }

  getSeverityIcons() {
    this.severity =
      [
        {
          severityType: 'Information',
          icon: 'fa-info-circle text-info'
        },
        {
          severityType: 'Error',
          icon: 'fa-exclamation-circle text-danger'
        },
        {
          severityType: 'Warning',
          icon: 'fa-warning text-warning'
        },
        {
          severityType: 'TodoInformation',
          icon: 'fa-calendar-check text-primary'
        }
      ];
  }

  /**Setting descriptive link - OEMP-215 */
  setNavigationLink(subCode, recordId) {
    this.queryParams = null;
    switch (subCode) {
      case Consts.NotificationSubType.TradeInstanceCompleted:
        this.clickHere = Consts.navigationLink.Vieworders;
        this.navigateURL = '/eclipse/tradeorder/list';
        break;
      case Consts.NotificationSubType.TradeInstanceError:
        this.clickHere = Consts.navigationLink.Vieworders;
        this.navigateURL = '/eclipse/tradeorder/list';
        break;
      case Consts.NotificationSubType.PendingOrders:
        this.clickHere = Consts.navigationLink.Viewpendings;
        this.navigateURL = '/eclipse/tradeorder/pending';
        break;
      case Consts.NotificationSubType.FixBlockOrder:
        this.clickHere = Consts.navigationLink.Viewclosedorders;
        this.navigateURL = '/eclipse/tradeorder/closed';
        break;
      case Consts.NotificationSubType.DataImportComplete:
        this.clickHere = Consts.navigationLink.Errors;
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount !== undefined && this.notificationVM.userNotification.errorCount > 0) {
            this.clickHere += `: ${this.notificationVM.userNotification.errorCount}`;
          }
        }

        this.navigateURL = '/eclipse/errorlogs';
        break;
      case Consts.NotificationSubType.ModelImportComplete:
        this.clickHere = Consts.navigationLink.Errors;
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount > 0 && this.notificationVM.userNotification.errorCount !== null) {
            this.clickHere += `: ${this.notificationVM.userNotification.errorCount}`;
          } else {
            this.clickHere = Consts.navigationLink.ViewImports;
          }
        }
        if (this.notificationVM.userNotification) {
          this.navigateURL = `/eclipse/admin/importlogs/detail/${this.notificationVM.userNotification.instanceId}`;
        }
        break;
      case Consts.NotificationSubType.FullAnalysisComplete:
        this.clickHere = Consts.navigationLink.Errors;
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount !== undefined && this.notificationVM.userNotification.errorCount > 0) {
            this.clickHere += `: ${this.notificationVM.userNotification.errorCount}`;
          }
        }
        this.navigateURL = '/eclipse/analyticserrorlogs';
        break;
      case Consts.NotificationSubType.TradesNeedApproval:
        this.clickHere = Consts.navigationLink.Vieworders;
        this.navigateURL = '/eclipse/tradeorder/list/2';
        break;
      case Consts.NotificationSubType.ModelAssignmentsNeedApproval:
        this.clickHere = Consts.navigationLink.Viewportfolios;
        this.navigateURL = '/eclipse/portfolio/list';
        break;
      case Consts.NotificationSubType.ModelNeedsApproval:
        this.clickHere = Consts.navigationLink.Viewmodel;
        this.navigateURL = '/eclipse/model/list';
        this.queryParams = {
          filter: 2
        };
        break;
      case Consts.NotificationSubType.CommunityModelUpdated:
        this.clickHere = Consts.navigationLink.Viewmodel;
        this.navigateURL = '/eclipse/model/list';
        break;
      case Consts.NotificationSubType.ImportComplete:
        if (this.notificationVM.userNotification) {
          if (this.notificationVM.userNotification.errorCount !== undefined && this.notificationVM.userNotification.errorCount !== 0) {
            this.clickHere = `${Consts.navigationLink.Errors}: ${this.notificationVM.userNotification.errorCount}`;
          } else {
            this.clickHere = Consts.navigationLink.ViewImports;
          }
        }
        this.navigateURL = `/eclipse/admin/importlogs/detail/${recordId}`;
        break;
      case Consts.NotificationSubType.TLHBuyBack:
        this.clickHere = Consts.navigationLink.ClickHere;
        const instanceId = this.notificationVM && this.notificationVM.userNotification ? this.notificationVM.userNotification.instanceId : null;
        if (!instanceId) {
          this.navigateURL = '/eclipse/tradeorder/instancesfilter';
        } else {
          this.navigateURL = `/eclipse/tradeorder/instancesfilter/${instanceId}`;
        }
        break;
      default:
        break;
    }
  }

  /**
   * This method will help us to observe progress notifications which polled configured time interval(say 60 seconds) ago.
   *  this will do progressNotificationsFallback action for every configured time interval
   */
  setTimeIntervalForProgressNotifications() {
    const configInterval = ConfigService.settings.fallbackIntervalInSeconds; // will get configInterval value in seconds
    this.intervalSubscribe = interval(1000 * configInterval).subscribe(x => {
      this.progressNotificationsFallback();
    });
  }

  /**
   * This method will find the process ids from notifications list which polled configured time interval(say 60 seconds) ago
   * if there are any, get the status of that particular notification based on process id.
   * if the status of that notification is active, keep that notification. Otherwise, remove that particular notification from progressNotificationsList.
   * After remove, if there are no notifications in progressNotificationsList, will hide the progress bar.
   */
  public progressNotificationsFallback() {
    /**get process ids of notifications which are not receiving more than configured time interval*/
    let processIDs = [];
    const configInterval = ConfigService.settings.fallbackIntervalInSeconds; // will get configInterval value in seconds
    const timeInterval = 1000 * configInterval; // ex: configInterval= 60 then, timeInterval = 60*1000 = 60000 millisecods = 1 minute(s)
    const progressNotifications = this.progressNotificationsList.filter(notification => {
      const difference = Math.abs(new Date().getTime() - notification.receivedOn.getTime());
      if (difference > timeInterval) {
        return notification;
      }
    });
    processIDs = progressNotifications.map(notification => {
      return notification.processId;
    });

    processIDs.forEach(processId => {
      this.notificationService.getProgressNotificationStatus(processId)
        .subscribe({
          next: (result: ProgressNotificationStatus) => {
            if (!result.isActive) {
              const existNotification = this.progressNotificationsList.find(item => item.processId === processId);
              const index = this.progressNotificationsList.indexOf(existNotification);
              this.progressNotificationsList.splice(index, 1);
              if (!this.progressNotificationsList.length) {
                this.showProgressBar = false;
              }
            /** If process id notification is inactive and its type is "analysis", then resetting import/analysis related buttons on overview dashboard */
              if (!!result.type) {
                if (result.type.toLocaleLowerCase() === 'analysis') {
                  this.notificationService.refreshOverviewDashboard();
                }
              }
            }
          },
          error: (err) => {
            if (err?.status === 401) { // This is most likely due to the token expiring.
              // On a 401 Unauthorized error, stop the interval polling.
              // Otherwise, the polling will continue to make unnecessary requests with bad tokens until the user logs out.
              this.intervalSubscribe?.unsubscribe();
            }
          }
        });
    });
  }
}
