<section style="display: flex; flex-direction: column; height: 100%;">
  <div class="page-header-row">
    <h3>Notes</h3>
  </div>
  <div style="margin-left: 1.5em">
    <eclipse-notes-list
      (onNoteTouched)="onNoteChanged()"
      [entityId]="portfolioEditorService.portfolioId$ | async"
      [entityType]="entityType">
    </eclipse-notes-list>
  </div>
</section>