<p-sidebar [(visible)]="visible" class="opl-editor" [fullScreen]="true" [modal]="true" [blockScroll]="true"
           [autoZIndex]="true" [showCloseIcon]="false">
  <ng-template pTemplate="header">
    <p-menubar class="opl-editor-header">
      <ng-template pTemplate="start">
        <div class="inline-flex align-items-center">
          <span class="opl-editor-header-icon portfolio">
            <i *ngIf="portfolio$ | async; else loading" class="fal fa-cubes"></i>
            <ng-template #loading>
              <p-skeleton size="40px"></p-skeleton>
            </ng-template>
          </span>
          <eclipse-portfolio-tree-dropdown [portfolioId]="_portfolioEditorService.portfolioId$ | async"
                                           (entityChanged)="onTreeEntityChanged($event)"></eclipse-portfolio-tree-dropdown>
        </div>
      </ng-template>
      <ng-template pTemplate="end">
        <div class="inline-flex align-items-center" style="align-items: center;">
          <button class="p-button-sm m-r-15" pButton icon="fas fa-arrow-up-right" label="Open in legacy Portfolio editor" (click)="openInLegacyEditor()"></button>
          <div class="text-right" *ngIf="portfolio$ | async as portfolio; else loading">
            <p class="opl-editor-header-value">
              {{ portfolio?.totalValue || 0 | currency }}
            </p>
            <p class="opl-editor-header-label">Total Value</p>
          </div>
          <ng-template #loading>
            <div class="text-right">
              <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
            </div>
          </ng-template>
          <p-divider layout="vertical"></p-divider>
          <div class="actionBtn btn-group pull-right">
            <button data-test-id="actions" label="Actions" iconPos="left" icon="fas fa-sparkles" pButton
                    class="p-button-text p-button-secondary" [disabled]="!(portfolio$ | async)?.id"
                    (click)="buildActionsMenu();menu.toggle($event)"></button>
            <p-tieredMenu #menu [model]="actionItems" [popup]="true" appendTo="body" baseZIndex="100"></p-tieredMenu>
          </div>
          <p-divider layout="vertical"></p-divider>
          <button type="button" pButton pRipple icon="pi pi-times" aria-label="Close Editor"
                  class="p-button-text p-button-secondary" (click)="closeEditor()"></button>
        </div>
      </ng-template>
    </p-menubar>
  </ng-template>
  <div class="opl-editor-content-container">
    <p-menu [model]="sidebarMenuItems$ | async" class="opl-editor-sidebar-menu"></p-menu>
    <div class="opl-editor-content">
      <ng-template #contentContainer></ng-template>
      <p-dialog [(visible)]="showConfirmReverseSyncPortfolios" modal="modal" [draggable]=false [resizable]=false [style]="{'width':'400px'}">
        <div>Are you sure you want to reverse sync portfolio {{_portfolioEditorService.portfolio.name}} to Orion Connect?</div>
        <p-footer>
          <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="showConfirmReverseSyncPortfolios=false" label="No"></button>
          <button type="button" pButton icon="fas fa-check" (click)="confirmReverseSyncPortfolios()" label="Yes"></button>
        </p-footer>
      </p-dialog>

      <p-dialog [(visible)]="showConfirmReverseSyncPortfolios" modal="modal" [draggable]=false [resizable]=false [style]="{'width':'400px'}">
        <div>Are you sure you want to reverse sync portfolio {{_portfolioEditorService.portfolio.name}} to Orion Connect?</div>
        <p-footer>
          <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="showConfirmReverseSyncPortfolios=false" label="No"></button>
          <button type="button" pButton icon="fas fa-check" (click)="confirmReverseSyncPortfolios()" label="Yes"></button>
        </p-footer>
      </p-dialog>

      <p-dialog [(visible)]="showReverseSyncPortfoliosMessage" modal="modal" [draggable]=false [resizable]=false [style]="{'width':'400px'}">
        <div>Portfolio {{_portfolioEditorService.portfolio.name}} sent to Orion Connect.
        </div>
        <p-footer>
          <button type="button" pButton (click)="showReverseSyncPortfoliosMessage=false" label="Ok"></button>
        </p-footer>
      </p-dialog>

      <p-dialog header="Confirm Sync From Orion Connect" [(visible)]="shouldShowPortfolioSyncFromOrionConfirmationDialog" modal="modal" [draggable]=false [resizable]=false [style]="{'width':'400px'}">
        <div>Are you sure you want to sync {{syncFromOrionPortfoliosCount}} portfolios from Orion Connect?</div>
        <p-footer>
          <button type="button" pButton (click)="shouldShowPortfolioSyncFromOrionConfirmationDialog=false" label="Cancel"></button>
          <button type="button" pButton (click)="confirmSyncFromOrionPortfolios()" label="Ok"></button>
        </p-footer>
      </p-dialog>

      <p-dialog header="Sync Request Status" [(visible)]="shouldShowRsyncStatusDialog" modal="modal" [draggable]=false [resizable]=false [style]="{'width':'400px'}">
        <div>{{rsyncStatus}}</div>
        <div hidden>{{rSyncErrorMessage}}</div>
        <p-footer>
          <button type="button" pButton (click)="shouldShowRsyncStatusDialog=false" [disabled]="!shouldEnableRsyncStatusOkButton" label="Ok"></button>
        </p-footer>
      </p-dialog>

      <div class="dashboard-setting-wrap" *ngIf="showExcludeFilterPopup">
        <eclipse-exclude-filter (resetExcludeFilter)="resetExcludeFilter()" [portfolioIds]="portfolioIdsToExclude">
        </eclipse-exclude-filter>
      </div>

      <eclipse-assign-accounts-portfolio (parentCallback)="callbackAssignPopup();" [showAssignPopup]="showAssignPopup">
      </eclipse-assign-accounts-portfolio>

      <p-dialog [(visible)]="displayConfirm" modal="true" [draggable]=false [resizable]=false class="ui-dialog-md">
        <div>This action will delete the Portfolio permanently. <br/> Do you wish to continue?</div>
        <p-footer>
          <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="displayConfirm=false"
                  label="No"></button>
          <button type="button" pButton icon="fas fa-check" (click)="deletePortfolio()" label="Yes"></button>
        </p-footer>
      </p-dialog>

      <p-dialog [(visible)]="deleteCheck" modal="true" [draggable]=false [resizable]=false class="ui-dialog-md">
        <div>You can not delete this Portfolio as there are Account(s) associated with it.</div>
        <p-footer>
          <button type="button" pButton (click)="deleteCheck=false" label="Ok"></button>
        </p-footer>
      </p-dialog>

      <p-dialog [(visible)]="deleteMessage" modal="true" [draggable]=false [resizable]=false class="ui-dialog-md">
        <div>Portfolios has been deleted. <span
          *ngIf="unDeletedPortfoliosCount > 0"> Cannot delete {{unDeletedPortfoliosCount}}
          Portfolios due to conflicts. </span>
        </div>
        <p-footer>
          <button type="button" pButton (click)="hideDeleteMessage()" label="Ok"></button>
        </p-footer>
      </p-dialog>

    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="opl-editor-footer" *ngIf="isFooterVisible">
      <ng-container *ngIf="portfolioEditPermission">
        <button pButton label="Save" [disabled]="!(portfolio$ | async)" (click)="savePortfolio()"></button>
        <button pButton label="Save and Close" [disabled]="!(portfolio$ | async)" (click)="savePortfolio(true)"></button>
      </ng-container>
      <button pButton class="p-button-secondary" (click)="closeEditor()" label="Cancel"></button>
    </div>
  </ng-template>
</p-sidebar>

