import { ChangeDetectorRef, Component, ElementRef, HostListener, inject, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/base.component';
import { DashboardService } from '../../services/dashboard.service';
import { Observable } from 'rxjs';
import {
  DashboardItemType,
  IDashboard,
  IDashboardCategory,
  IDashboardDetail,
  IDashboardField,
  IDashboardSelectedField
} from '../../models/dashboard';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { uniqBy as _uniqBy, orderBy as _orderBy, sortBy as _sortBy} from 'lodash';
import { CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { IDashboardSummary } from '../../models/mainDashboard';
import { AlertService, SessionHelper } from '../../core';
import { Utils as Util } from '../../core/functions';
import * as Consts from '../../libs/app.constants';
import { IDashboardCustomize } from '../../models/dashboardfilter';
import { MainDashboardFilterComponent } from './dashboardfilter.component';
import { IUser } from '../../models/user';
import { isEqual as _isEqual } from 'lodash';
import { SplitIoService } from "../../core/feature-flag/splitio.service";
import { IRolePrivilege } from '../../models/rolePrivileges';

@Component({
  selector: 'eclipse-main-dashboard-container',
  templateUrl: './main-dashboard-container.component.html',
  styleUrls: ['./main-dashboard-container.component.scss'],
  providers: [DashboardService], // provides the same instance of the DashboardService to all child cards
})
export class MainDashboardContainerComponent extends BaseComponent {
  private readonly _dashboardService: DashboardService = inject(DashboardService);
  private readonly _changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly _splitIoService: SplitIoService = inject(SplitIoService);
  private readonly _alertService: AlertService = inject(AlertService);

  @ViewChild('filterDropDown') filterDropDown: ElementRef;
  @ViewChild('editActionsDropDown') editActionsDropDown: ElementRef;
  @ViewChild('dashboardFilter') dashboardFilterComponent: MainDashboardFilterComponent;
  @ViewChild('dlg') listGroup: CdkDropListGroup<CdkDropList>;

  @Input() dashboardSummary: IDashboardSummary;

  public availableDashboards: IDashboard[] = [];
  public teamDashboards: IDashboard[] = [];
  public currentDashboard: IDashboard;
  public currentDashboardCopy: IDashboard; // backup of the dashboard in case the user wants to reset/cancel edits made to the layout
  public dashboardFields: IDashboardField[];
  public dashboardCategories: IDashboardCategory[];
  public selectableFields: IDashboardSelectedField[] = [];
  public filteredSelectableFields: IDashboardSelectedField[] = [];
  public isEditing: boolean = false;
  public isSaving: boolean = false;
  public canAddSavedView: boolean = false;
  public canEditDashboard: boolean = false;
  public actionSearchText: string = null;
  public showResetWarning: boolean = false;
  private publicViewsPrivilege: IRolePrivilege;
  public dashboardItemType = DashboardItemType;
  private currentUser: IUser;
  public dashboard$: Observable<IDashboard[]>;
  public activeDashboardTabIndex = 0;

  public firmDashboardPublicViewsFlag: boolean = false;

  // Filtering
  public isFilterExpanded: boolean = false;

  // Drag and drop
  target: CdkDropList | null;
  source: CdkDropList | null;
  files: File[] = [];

  public get otherDashboards(): IDashboard[] {
    return this.availableDashboards.filter(d => !d.teamId);
  }

  private _newId = -1;
  private get newId(): number {
    return --this._newId;
  }

  public get actionsSelected(): number {
    return this.selectableFields.filter(a => a.selected).length;
  }

  constructor(public readonly sessionHelper: SessionHelper) {
    super('', sessionHelper);
    this._splitIoService.flagsEnabled([
      'TEXP_public_views_firm_action_items_8979',
    ])
      .subscribe(flags => {
        this.firmDashboardPublicViewsFlag = !!flags['TEXP_public_views_firm_action_items_8979'];
      });
    this.publicViewsPrivilege = Util.getPermission(Consts.PRIV_DASHBOARD_DEFAULTS)
  }

  ngOnInit() {
    this.currentUser = this._sessionHelper.getUser();
    this.getDashboardFields()
      .pipe(take(1),
        switchMap(() => {
          this.getDashboardsForUser(this.currentUser.id); // don't pass a team.  the api returns all accessible dashboards.
          return this.dashboard$;
        })
      )
      .subscribe();
  }

  public getDashboardFields(): Observable<IDashboardField[]> {
    return this._dashboardService.getDashboardFields()
      .pipe(tap((data) => {
        this.dashboardFields = data;
        const categories = this.dashboardFields
          .filter(df => this._dashboardService.isDashboardFieldVisible(df))
          .map(f => f.dashboardCategory);
        this.dashboardCategories = _uniqBy(categories, 'id');
        this.dashboardCategories.unshift(<IDashboardCategory>{name: 'All items', id: -1});
        this.dashboardCategories.forEach(c => c.selected = true);
      }));
  }

  public onSelectedCategoryChange(category: IDashboardCategory): void {
    if (category.id === -1) {
      this.dashboardCategories.filter(c => c.id > 0).forEach(c => c.selected = category.selected);
    } else {
      const allCategoriesItem = this.dashboardCategories.find(c => c.id === -1);
      allCategoriesItem.selected = (category.id === -1 && category.selected)
        || this.dashboardCategories.filter(c => c.id > 0).every(c => c.selected);
    }
  }

  public filterActionItems(): void {
    if (!this.actionSearchText || !this.actionSearchText.length) {
      this.filteredSelectableFields = this.selectableFields.slice();
      return;
    }
    const searchText = this.actionSearchText.toLowerCase();
    this.filteredSelectableFields = this.selectableFields.filter(i => i.name.toLowerCase().indexOf(searchText) >= 0);
  }

  public createBlankDashboard(teamId?: number, userId?: number): IDashboard {
    let dashboardName = 'My Action Items';
    let isPrimaryTeam = false;
    if (!teamId && !userId) {
      dashboardName = 'Firm Action Items';
    } else if (teamId) {
      return null;
    }
    return <IDashboard>{
      id: 0,
      name: dashboardName,
      teamId: teamId || null,
      userId: userId || null,
      isPrimaryTeam: isPrimaryTeam,
      dashboardDetails: []
    };
  }

  public createPlaceholderDashboards(existingDashboards: IDashboard[]): IDashboard[] {
    let placeholderDashboards = [];
    this.teamDashboards = [];

    // add an empty firm dashboard if this is a firm admin and a firm dashboard doesn't exist
    if ((this.isFirmAdmin || this.isOrionAdmin) && (!existingDashboards.find(d => !d.teamId && !d.userId && !d.isDeleted))) {
      placeholderDashboards.push(this.createBlankDashboard());
    }

    // Create a placeholder dashboard for the user if they do not have a dashboard already
    if (!existingDashboards.find(d => !!d.userId && !d.isDeleted)) {
      const placeholder = this.createBlankDashboard(null, this.currentUser.id);
      if (placeholder) {
        placeholderDashboards.push(placeholder);
      }
    }

    placeholderDashboards = placeholderDashboards.concat(existingDashboards);
    placeholderDashboards = _orderBy(placeholderDashboards, ['teamId', 'userId'], ['desc', 'desc']);

    return placeholderDashboards;
  }

  public checkEditDashboardPermission(): boolean {
    if (!this.currentDashboard) {
      return false;
    }
    const dashboardDefaultPrivilege = Util.getPermission(Consts.PRIV_DASHBOARD_DEFAULTS);

    // firm level
    if (!this.currentDashboard.teamId && !this.currentDashboard.userId) {
      return dashboardDefaultPrivilege?.canUpdate && (this.isFirmAdmin || this.isOrionAdmin);
    } else if (this.currentDashboard.teamId) { // team level
      const primaryTeam = this.currentUser.primaryTeam;
      return dashboardDefaultPrivilege?.canUpdate
        && (this.isFirmAdmin || this.isOrionAdmin
          || (this.isTeamAdmin && this.currentDashboard.teamId === primaryTeam));
    } else {
      return true;
    }
  }

  public getDashboardsForUser(userId?: number, selectDashboardId?: number): void {
    this.dashboard$ = this._dashboardService.getDashboardForUser(userId)
      .pipe(
        map((dashboards: IDashboard[]) => this.processDashboards(dashboards)),
        tap((dashboards: IDashboard[]) => {
        this.availableDashboards = this.createPlaceholderDashboards(dashboards);
        // select the dashboard with the given id, or get the default dashboard if no id is specified
        const selectedDashboard = this.availableDashboards.find(d => !!selectDashboardId && d.id === selectDashboardId) || this.getDefaultDashboard();
        this.switchDashboard(selectedDashboard);
        this.isEditing = false;
        this.isSaving = false;
      }));
  }

  public getDefaultDashboard(): IDashboard {
    // if no saved dashboards exist, select the My Action Items
    if (!this.availableDashboards?.filter(d => d.id > 0)) {
      return this.availableDashboards.find(d => !!d.userId);
    }
    const userDashboard = this.otherDashboards?.find(d => !!d.userId && d.id);
    const firmDashboard = this.otherDashboards?.find(d => !d.teamId && !d.userId && d.id);
    // get the first team dashboard with content.  array is already sorted with primary team first.
    const teamDashboard = this.teamDashboards?.find(d => !d.userId && d.id);
    const fallbackDashboard = this.availableDashboards?.length ? this.availableDashboards[0] : null;

    return userDashboard || teamDashboard || firmDashboard || fallbackDashboard;
  }

  public processDashboards(dashboards: IDashboard[]): IDashboard[] {
    // sort the dashboard details by displayIndex so they appear properly on the page
    dashboards
      .filter(d => d.dashboardDetails)
      .forEach(d => {
        // remove any dashboard details that contain fields that aren't accessible
        const filteredDetails = d.dashboardDetails.filter(dd => this._dashboardService.isDashboardFieldVisible(dd.dashboardField));
        d.dashboardDetails = d.dashboardDetails.filter(detail => !detail.isDeleted);
        d.dashboardDetails = _sortBy(filteredDetails, 'displayIndex');
        for(let i = 0; i < d.dashboardDetails.length; i++) {
          d.dashboardDetails[i].displayIndex = i;
          d.dashboardDetails[i].type = !!d.dashboardDetails[i].userGridViewId ? DashboardItemType.SavedView : DashboardItemType.Metric;
        }
      });
    dashboards.forEach(d => {
      if (!d.userId && !d.teamId) {
        d.name = 'Firm Action Items';
      } else if (!!d.userId) {
        d.name = 'My Action Items';
      } else {
        const team = this.currentUser.teams.find(t => t.id === d.teamId);
        d.name = `${team?.name || 'Team'} Action Items`;
        d.isPrimaryTeam = team?.id === this.currentUser.primaryTeam;
      }
    });
    return dashboards.filter(d => !d.teamId);
  }

  public switchDashboard(dashboard: IDashboard): void {
    this.currentDashboard = dashboard;
    this.activeDashboardTabIndex = !!dashboard.userId ? 1 : 0; // User dashboard is always 2nd, so if a user id exists choose the last index
    this.refreshSelectableFields();
    this.canEditDashboard = this.checkEditDashboardPermission();
    this.canAddSavedView =
      // Users can add saved views to their own dashboards
      (this.currentDashboard.userId === this.currentUser.id)
      // or add saved views to firm dashboards if they can edit the dashboard and have Public Views add/update privileges.
      || (this.firmDashboardPublicViewsFlag && this.canEditDashboard && (this.publicViewsPrivilege.canUpdate || this.publicViewsPrivilege.canAdd));
  }

  public refreshSelectableFields(): void {
    const fieldList = this.dashboardFields
      .filter(df => this._dashboardService.isDashboardFieldVisible(df))
      .map(df => ({
          id: df.id,
          categoryName: df.dashboardCategory.name,
          name: df.displayName,
          selected: false
        })
      );
    this.currentDashboard?.dashboardDetails.forEach(dd => {
      const foundField = fieldList.find(a => a.id === dd.dashboardFieldId);
      if (foundField && !dd.isDeleted) {
        foundField.selected = true;
      }
    });
    this.selectableFields = _sortBy(fieldList, ['categoryName', 'name']);
    this.filterActionItems();
  }

  onDeleteSavedView(detail: IDashboardDetail) {
    if (detail.id <= 0) {
      this.currentDashboard.dashboardDetails = this.currentDashboard.dashboardDetails.filter(d => d.id !== detail.id);
    } else {
      detail.isDeleted = true;
    }
  }

  public onDeleteField(fieldId: number): void {
    const selectedField: IDashboardSelectedField = this.selectableFields.find(f => f.id === fieldId);
    selectedField.selected = false;
    this.fieldToggled(selectedField);
  }

  public fieldToggled(selectedField: IDashboardSelectedField) {
    const existingField = this.currentDashboard.dashboardDetails.find(dd => dd.dashboardFieldId === selectedField.id);
    if (!!existingField) {
      const index = this.currentDashboard.dashboardDetails.indexOf(existingField);
      // showing an existing field
      if (selectedField.selected) {
        existingField.isDeleted = false;
        // put the field at the end of the array so it doesn't mess up the sorting
        this.currentDashboard.dashboardDetails.push(this.currentDashboard.dashboardDetails.splice(index, 1)[0]);
        existingField.displayIndex = this.currentDashboard.dashboardDetails.filter(dd => !dd.isDeleted).length;
      } else if (existingField.id < 0) { // deleting a field that hasn't been saved yet (just discard it)
        this.currentDashboard.dashboardDetails.splice(this.currentDashboard.dashboardDetails.indexOf(existingField), 1);
      } else { // deleting an existing field.  Mark it as deleted so it saves.
        existingField.isDeleted = true;
      }
    } else { // new field that hasn't been added to the dashboard before
      this.currentDashboard.dashboardDetails.push(<IDashboardDetail>{
        dashboardFieldId: selectedField.id,
        dashboardField: this.dashboardFields.find(df => df.id === selectedField.id),
        id: this.newId,
        dashboardId: this.currentDashboard.id,
        displayIndex: this.currentDashboard.dashboardDetails.filter(dd => !dd.isDeleted).length,
        isDeleted: false,
        type: DashboardItemType.Metric,
        allowPrivateSavedViews: !!this.currentDashboard.userId // allow private saved views only on user level dashboards
      });
    }
  }

  drop(event: CdkDragDrop<IDashboardDetail>) {
    moveItemInArray(
      this.currentDashboard.dashboardDetails,
      event.previousContainer.data.displayIndex,
      event.container.data.displayIndex
    );

    this._changeDetector.detectChanges();

    for (let i = 0; i < this.currentDashboard.dashboardDetails.length; i++) {
      this.currentDashboard.dashboardDetails[i].displayIndex = i;
    }
  }

  // prevent the filter dropdown from closing when a child checkbox is clicked
  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    const target = event.target as HTMLElement;

    if (this.filterDropDown) {
      const filterDropDownEl = this.filterDropDown.nativeElement as HTMLElement;
      if (filterDropDownEl && target && filterDropDownEl.contains(target)) {
        event.stopPropagation();
      }
    }
    if (this.editActionsDropDown) {
      const editActionsDropDownEl = this.editActionsDropDown.nativeElement as HTMLElement;
      if (editActionsDropDownEl && target && (editActionsDropDownEl.contains(target) || (!!event.composedPath && event.composedPath()?.indexOf(editActionsDropDownEl) >= 0))) {
        event.stopPropagation();
      }
    }
  }

  public startEditing(): void {
    if (this.isEditing) {
      return;
    }
    if (!this.currentDashboard) {
      this.currentDashboard = <IDashboard>{
        id: 0,
        userId: this.currentUser.id,
        teamId: null,
        dashboardDetails: [],
        name: 'My Action Items'
      };
    }

    // make a quick deep copy of the current dashboard.
    // this will be used in case the user cancels/resets the changes.
    this.currentDashboardCopy = JSON.parse(JSON.stringify(this.currentDashboard));
    this.isEditing = true;
  }

  public cancelEditing(): void {
    this.isEditing = false;
    if(!_isEqual(this.currentDashboard, this.currentDashboardCopy)) {
      this.currentDashboard = this.currentDashboardCopy;
    }
    this.refreshSelectableFields();
  }

  public resetLayout(): void {
    this.showResetWarning = true;
  }

  public confirmReset(): void {
    this.showResetWarning = false;
    if (this.currentDashboard.id) {
      this.currentDashboard.isDeleted = true;
      this.currentDashboard.dashboardDetails.forEach(dd => dd.isDeleted = true);
      this.saveChanges();
    } else {
      this.isEditing = false;
      this.currentDashboard = this.currentDashboardCopy;
    }
  }

  public saveChanges(): void {
    const invalidSavedViewDetails = this.currentDashboard.dashboardDetails.filter(dd => !dd.isDeleted && dd.type === DashboardItemType.SavedView && !dd.userGridViewId);
    if (invalidSavedViewDetails.length) {
      this._alertService.alert.emit([{typeId: 4, message: 'A view must be selected for all saved view cards'}]);
      return;
    }

    this.isSaving = true;

    this._dashboardService.updateDashboard(this.currentDashboard)
      .subscribe({
        next: (result: IDashboard) => {
          let dashboardId: number;

          // if the saved dashboard is still active, set it to be re-selected
          if (!result.isDeleted && result.id) {
            dashboardId = result.id;
          }

          this.getDashboardsForUser(this.currentUser.id, dashboardId);
        },
        error: () => {
          this.cancelEditing();
          this.isSaving = false;
        }
      });
  }

  public onItemCustomizing(item: IDashboardDetail, customizeData: IDashboardCustomize): void {
    this.dashboardFilterComponent.getDashboardFilterData(customizeData.type, customizeData.filterId, customizeData.overridden);
    this.isFilterExpanded = true;
    item.isCustomizing = true;
  }

  public resetDashboardSetting(): void {
    this.isFilterExpanded = false;
    // Refresh the dashboard data
    this._dashboardService.refreshDashboardData();
  }

  public onHideFilterCustomizing(): void {
    this.currentDashboard.dashboardDetails.forEach(detail => detail.isCustomizing = false);
  }

  /**
   * Creates a new saved view dashboard item, adds it to the dashboard, and puts the
   * dashboard into edit mode.
   */
  addSavedView(): void {
    // Start editing the dashboard (if not already editing)
    this.startEditing();
    //Create the new saved view card
    const newDashboardDetail: IDashboardDetail = <IDashboardDetail>{
      id: this.newId,
      dashboardId: this.currentDashboard.id,
      displayIndex: this.currentDashboard.dashboardDetails.filter(dd => !dd.isDeleted).length,
      isDeleted: false,
      type: DashboardItemType.SavedView,
      allowPrivateSavedViews: !!this.currentDashboard.userId // allow private saved views only on user level dashboards
    };
    //Add the card so it appears on the dashboard
    this.currentDashboard.dashboardDetails.push(newDashboardDetail);

    // Scroll the new card into view
    setTimeout(() => {
      const detailElement = document.getElementById(`item-${newDashboardDetail.id}`);
      detailElement?.scrollIntoView(true);
    },0);
  }

  public onDashboardTabChange(evt: any): void {
    let dashboard: IDashboard;
    if(evt.index === 1) { // user dashboard
      dashboard = this.availableDashboards.find(d => d.userId);
    } else { // firm dashboard
      dashboard = this.availableDashboards.find(d => !d.userId && !d.teamId);
    }
    this.switchDashboard(dashboard);
  }
}
