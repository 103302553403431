import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../core/base.component';
import { TomService } from '../../../services/tom.service';
import { PortfolioAutoCompleteComponent } from '../../../shared/search/portfolio.autocomplete.component';
import { AccountsAutoCompleteComponent } from '../../../shared/search/account.autocomplete.component';
import { SecurityService } from '../../../services/security.service';
import { IQuickTradeData, ITradeOrderMessage, IValidateTrade, IValidateTrades } from '../../../models/tom';
import * as moment from 'moment-timezone';
import { ActionType } from '../../../libs/app.constants';
import * as _ from 'lodash';
import { TradeToolsService } from '../../../services/tradetools.service';
import { TradeInstanceSubType, TradeInstanceType } from '../../../models/tradetools';
import { InputNumber } from 'primeng/inputnumber';
import { IPortfolioAnalytics } from '../../../models/portfolio';
import { AnalyticsHelper } from '../../../shared/analytics-duration/analytics-helper';
import { IAnalyticsNotification } from '../../../models/notification';
import { SplitIoService } from '../../../core/feature-flag';

@Component({
  selector: 'eclipse-tradeorder-quicktrade',
  templateUrl: './quicktrade.component.html'
})
export class QuickTradeComponent extends BaseComponent {
  @ViewChild(PortfolioAutoCompleteComponent) portfolioAutoCompleteComponent: PortfolioAutoCompleteComponent;
  @ViewChild(AccountsAutoCompleteComponent) accountsAutoCompleteComponent: AccountsAutoCompleteComponent;
  @ViewChild('dollarsInput') dollarsInput: InputNumber;
  @ViewChild('sharesInput') sharesInput: InputNumber;
  @ViewChild('percentInput') percentInput: InputNumber;

  public tradeFilterMethod: 'account' | 'portfolio';
  actions: any[] = [];
  placeHolder: string;
  dollarsPlaceHolder: string;
  placeHolderAction: string;
  placeHolderShares: string;
  isTradeActionSell: boolean = false;
  showTradeType: boolean = true;
  showDollar: boolean = true;
  showSellSecuritySearch: boolean = false;
  showBuySecuritySearch: boolean = true;
  showShares: boolean = true;
  showValidate: boolean = false;
  quickTradeData: IValidateTrade = <IValidateTrade>{};

  buySecuritySuggestions: any[] = [];
  sellSecuritySuggestions: any[] = [];
  selectedBuySecurity: any;
  selectedSellSecurity: any;
  selectedSecurityId: number;
  selectedItem: any;
  selectedItemIds: number[] = [];

  accountId: number;
  accountValue: number;
  currentCash: number;
  postTrade: number = 0;
  cashReserv: number;
  modelName: string;
  tickerSymbol: string;
  LastPrice: number;
  asof: string;
  instructions: string;
  ask: number;
  askSize: number;
  bid: number;
  bidSize: number;
  dayHigh: number;
  dayLow: number;
  previousClose: number;
  change: number;
  changePercent: number;
  volume: number;
  open: number;
  isRealTimeQuote: boolean = false;

  showAccountsTab: boolean = false;
  showPortfolioTab: boolean = false;
  isSearchSelected: boolean = false;
  accountAssets: any[] = [];
  errorMessage: string;
  showPriceTab: boolean = false;
  isDisableDollor: boolean = true;
  isDisableShare: boolean = true;
  isDisablePercent: boolean = true;
  showErrorMessage: boolean = false;
  selectedOperation: number = null;
  showTabs: boolean = false;
  showSendTradeImmediatley: boolean = true;
  isDisableAdd: boolean = false;
  isDisableValidate: boolean = false;
  isDisableReset: boolean = false;
  radioBtnChecked: number = 1;

  // to show percentage validation
  percent: number;
  isInValidPercent: boolean = false;
  isTotalPercentExceed: boolean = false;
  warningMessages: any[] = [];
  warningMessage: string;
  showWarningMessage: boolean = false;
  realTimePriceError: boolean = false;
  tradeOrderErrorMessages: ITradeOrderMessage[];
  portfolioAnalytics: IPortfolioAnalytics[];
  quickTradeAstroSMAff: boolean = false;

  constructor(private _tomService: TomService, private _tradeToolsService: TradeToolsService,
              private _securityService: SecurityService,
              private readonly _splitIoService: SplitIoService) {
    super();

    this.quickTradeAstroSMAff = this._splitIoService.featureFlags['eclipse_quicktrade_sma_astro_9983'];

    this.setDefaults();
    this.tradeFilterMethod = 'account';
    this.portfolioAnalytics = [];

    this.getTradeOrderMessages();
  }
  ngOnInit() {
    this.getActions();
  }

  private getTradeOrderMessages(): void {
    this._tomService.getAllTradeOrderMessages().subscribe(messages => {
      this.tradeOrderErrorMessages = messages;
    });
  }

  /** Fires on radio button change  of account/portfolio*/
  radioBtnChange(selTradeFilterMethod) {
    this.selectedItem = undefined;
    this.accountId = undefined;
    this.actions = undefined;
    this.tradeFilterMethod = selTradeFilterMethod;
    this.getActions();
    this.setDefaults();
    this.isSearchSelected = false;
    this.selectedSellSecurity = undefined;
    this.selectedBuySecurity = undefined;
    this.showErrorMessage = false;
    this.showWarningMessage = false;
    // as per new change for portfolio no buy/sell
    if (this.tradeFilterMethod === 'portfolio') {
      this.showBuySecuritySearch = false;
      this.showDollar = false;
      this.showShares = false;
      this.showSendTradeImmediatley = false;
    }
  }

  /** gets orderTypes */
  getActions() {
    if (this.tradeFilterMethod === 'account') {
      this._tomService.getAccountActions()
        .subscribe(model => {
            this.actions = model;
          },
          error => {
            console.error(error);
          });
    } else {
      this._tomService.getPortfolioActions()
        .subscribe(model => {
            this.actions = model;
          },
          error => {
            console.error(error);
          });
    }
    /** if user toggle between account and portfolio after selection account/portfolio */
    this.showTabs = false;
  }

  /** changing placeholder text for Action change--Buy/Sell  */
  onActionChange(params) {
    this.selectedSecurityId = undefined;
    // eslint-disable-next-line eqeqeq
    if (params == ActionType.BUY) {
      this.placeHolder = 'Product or ticker to buy';
      this.dollarsPlaceHolder = 'Amount to buy';
      this.placeHolderAction = 'Buy';
      this.placeHolderShares = 'Quantity to buy';
      this.isTradeActionSell = false;
      this.showErrorMessage = false;
      this.showWarningMessage = false;
      this.selectedSellSecurity = undefined;

    // eslint-disable-next-line eqeqeq
    } else if (params == ActionType.Sell) {
      this.placeHolder = 'Product or ticker to sell';
      this.dollarsPlaceHolder = 'Amount to sell';
      this.placeHolderAction = 'Sell';
      this.placeHolderShares = 'Quantity to sell';
      this.isTradeActionSell = true;
      this.showTradeType = false;
      this.showErrorMessage = false;
      this.showWarningMessage = false;
      this.selectedBuySecurity = undefined;
    // eslint-disable-next-line eqeqeq
    } else if (params == ActionType.Sell_Rebalance) {
      this.dollarsPlaceHolder = 'Amount to sell';
      this.showErrorMessage = false;
      this.showWarningMessage = false;
    // eslint-disable-next-line eqeqeq
    } else if (params == ActionType.Buy_Rebalance) {
      this.dollarsPlaceHolder = 'Amount to buy';
      this.showErrorMessage = false;
      this.showWarningMessage = false;
    }

    this.showControls(params);
  }

  /** Based on action Type controls will be shown */
  showControls(id: ActionType) {
    // eslint-disable-next-line eqeqeq
    if (id == ActionType.BUY) {
      this.showTradeType = true;
      this.showDollar = true;
      this.showBuySecuritySearch = true;
      this.showSellSecuritySearch = false;
      this.showShares = true;
      this.isTradeActionSell = false;
      this.showValidate = true;
      this.showSendTradeImmediatley = true;
    // eslint-disable-next-line eqeqeq
    } else if (id == ActionType.Sell) {
      this.showTradeType = false;
      this.showDollar = true;
      this.showSellSecuritySearch = true;
      this.showBuySecuritySearch = false;
      this.showShares = true;
      this.isTradeActionSell = true;
      this.showValidate = true;
      this.showSendTradeImmediatley = true;
    // eslint-disable-next-line eqeqeq
    } else if (id == ActionType.Rebalance) {
      this.showTradeType = true;
      this.showDollar = false;
      this.showSellSecuritySearch = false;
      this.showBuySecuritySearch = false;
      this.showShares = false;
      this.isTradeActionSell = false;
      this.showPriceTab = false;
      this.showValidate = false;
      this.showSendTradeImmediatley = false;
    // eslint-disable-next-line eqeqeq
    } else if (id == ActionType.Buy_Rebalance) {
      this.showTradeType = true;
      this.showDollar = true;
      this.showSellSecuritySearch = false;
      this.showBuySecuritySearch = false;
      this.showShares = false;
      this.isTradeActionSell = false;
      this.showPriceTab = false;
      this.showValidate = false;
      this.showSendTradeImmediatley = false;
    // eslint-disable-next-line eqeqeq
    } else if (id == ActionType.Sell_Rebalance) {
      this.showTradeType = false;
      this.showDollar = true;
      this.showSellSecuritySearch = false;
      this.showBuySecuritySearch = false;
      this.showShares = false;
      this.isTradeActionSell = false;
      this.showPriceTab = false;
      this.showValidate = false;
      this.showSendTradeImmediatley = false;
    // eslint-disable-next-line eqeqeq
    } else if (id == ActionType.Liquidate) {
      this.showTradeType = false;
      this.showDollar = false;
      this.showSellSecuritySearch = false;
      this.showBuySecuritySearch = false;
      this.showShares = false;
      this.isTradeActionSell = false;
      this.showPriceTab = false;
      this.showValidate = false;
      this.showSendTradeImmediatley = false;
    }
    this.isDisableReset = false;
    this.isDisableValidate = false;
    this.isDisableAdd = false;
    this.disableAndEnableButtonByAnalyticsStatus(this.selectedItem, id, false);
  }

  /** To get selected item from auto complete */
  getSelectedItem(item) {
    if (item.value && item.value.id) {
      this.selectedItem = item.value;
      this.selectedItem.portfolioId = this.radioBtnChecked ? this.selectedItem.portfolioId : this.selectedItem.id;
      this.selectedItemIds = []; // To clear array items on every time account/portfolio selection
      this.selectedItemIds.push(this.selectedItem.id);
      this.getAccountDetails(this.selectedItem.id);
      this.isSearchSelected = true;
      this.getAssetsforAccount(this.selectedItem.id);
      this.showErrorMessage = false;
      this.showWarningMessage = false;
      this.isDisableAdd = false;
      this.isDisableReset = false;
      this.selectedSecurityId = undefined;
      const portfolioAnalytic = {
        failedReason: item.value.failedReason,
        needAnalytics: item.value.needAnalytics,
        portfolioId: item.value.portfolioId ?? item.value.id,
        editedDate: item.value.analyticsEditedDate
      } as IPortfolioAnalytics;
      this.portfolioAnalytics.push(portfolioAnalytic);
      this.selectedItem.portfolioFlags = this.portfolioAnalytics;
      this.disableAndEnableButtonByAnalyticsStatus(this.selectedItem, this.quickTradeData.action, false);
    } else {
      this.portfolioAnalytics = [];
      this.selectedItem = undefined;
      this.selectedItemIds = []; // To clear array items on every time account/portfolio selection
    }
  }

  /**Buy Security Search */
  buySecuritySearch(event) {
    /** To avoid buy security search, when we enter string in account auto complete search box */
    // eslint-disable-next-line eqeqeq
    if ((typeof (this.accountsAutoCompleteComponent.selectedItem) == 'string')) {
      this.selectedItemIds = [];
    }

    if (this.selectedItemIds.length > 0) {
      /** To fetch buy securities only when item(account) is selected   */
      this._securityService.searchBuySecurity(event.query)
        .subscribe((securitiesResult: any[]) => {
          securitiesResult.forEach((x: { nameSymbol: string; name: string; symbol: string }) => x.nameSymbol = `${x.name} (${x.symbol})`);

          this.buySecuritySuggestions = securitiesResult.filter(a => (a?.isDeleted ?? 0) === 0);
          this.showErrorMessage = false;
        });
    } else {
      this.buySecuritySuggestions = [];
    }
  }

  /** security selection */
  onBuySecuritySelect(item) {
    this.selectedSecurityId = item.id;
    this.tickerSymbol = item.symbol;
    this.getPriceDetails(item.id);
    this.showPriceTab = true;
    this.showTabs = true;
    this.showControls(this.quickTradeData.action);
  }

  /**sell Security Search */
  sellSecuritySearch(event) {
    /** To avoid sell security search, when we enter string in account auto complete search box */
    // eslint-disable-next-line eqeqeq
    if ((typeof (this.accountsAutoCompleteComponent.selectedItem) == 'string')) {
      this.selectedItemIds = [];
    }

    if (this.selectedItemIds.length > 0) {
      /** To fetch sell securities only when item(account) is selected   */
      this._securityService.getSellSecurities(event.query, this.tradeFilterMethod, this.selectedItemIds)
        .subscribe(securitiesResult => {
          securitiesResult.forEach((x: { nameSymbol: string; name: string; symbol: string }) => x.nameSymbol = `${x.name} (${x.symbol})`);

          this.sellSecuritySuggestions = securitiesResult;
          this.showErrorMessage = false;
        });
    } else {
      this.sellSecuritySuggestions = [];
    }
  }

  /** Fires on security selection */
  onSellSecuritySelect(item) {
    this.selectedSecurityId = item.id;
    this.tickerSymbol = item.symbol;
    this.getPriceDetails(item.id);
    this.showPriceTab = true;
    this.showTabs = true;
    this.showControls(this.quickTradeData.action);
  }

  resetPartialForm() {
    this.selectedSellSecurity = undefined;
    this.selectedBuySecurity = undefined;
    this.quickTradeData.tradeAmount = undefined;
    this.quickTradeData.tradePercent = undefined;
    this.quickTradeData.tradeShares = undefined;
    this.showErrorMessage = false;
    this.showTabs = false;
    this.selectedOperation = null;
    this.isInValidPercent = false;
    this.isTotalPercentExceed = false;
    this.isDisableAdd = false;
    this.isDisableDollor = true;
    this.isDisableShare = true;
    this.isDisableValidate = false;
    this.isDisableReset = false;
  }

  /** resetting quick trade to default */
  resetForm() {
    this.quickTradeData.action = ActionType.BUY;
    this.selectedSellSecurity = undefined;
    this.selectedBuySecurity = undefined;
    this.quickTradeData.tradeAmount = undefined;
    this.quickTradeData.tradePercent = undefined;
    this.quickTradeData.tradeShares = undefined;
    this.quickTradeData.accountId = undefined;
    this.quickTradeData.securityId = undefined;
    this.selectedSecurityId = undefined;
    this.selectedItemIds = [];
    this.selectedItem = {}; // To clear selected account/portfolio on reset button click
    this.clearFilterAutoComplete();
    this.showErrorMessage = false;
    this.setDefaults();
    this.showTabs = false;
    this.selectedOperation = null;
    this.isSearchSelected = false;
    this.isInValidPercent = false;
    this.isTotalPercentExceed = false;
    this.isTradeActionSell = false;
    this.isDisableAdd = false;
    this.isDisableDollor = true;
    this.isDisableShare = true;
    this.isDisableValidate = false;
    this.isDisableReset = false;
  }

  /** To clear trade filters auto complete  */
  clearFilterAutoComplete() {
    if (this.tradeFilterMethod === 'portfolio') {
      this.portfolioAutoCompleteComponent.selectedItem = undefined;
      this.isInValidPercent = false;
      this.isTotalPercentExceed = false;
    } else if (this.tradeFilterMethod === 'account') {
      this.accountsAutoCompleteComponent.selectedItem = undefined;
 }
  }

  /** gets account details to populate account tab  */
  getAccountDetails(id) {
    this.selectedBuySecurity = undefined;
    this.selectedSellSecurity = undefined;
    if (this.tradeFilterMethod === 'account') {
      this._tomService.getAccountDetails(id)
        .subscribe(model => {
            this.accountId = model.accountId;
            this.accountValue = model.accountValue.totalValue;
            this.currentCash = model.summarySection.pendingCashValue;
            this.cashReserv = model.summarySection.cashReserve;
            this.postTrade = 0;
            // Fix for OEF-3388
            this.instructions = model.instructions;
            this.showTabs = true;
            this.showAccountsTab = true;
            this.showPortfolioTab = false;
          },
          error => {
            console.error(error);
          });
    } else {
      this._tomService.getPortfolioDetails(id)
        .subscribe(model => {
            this.accountId = model.id;
            this.accountValue = model.summary.AUM.totalValue;
            this.currentCash = model.summary.AUM.totalCash.cash;
            this.cashReserv = model.summary.AUM.totalCash.reserve;
            this.modelName = model.general.modelName;
            this.instructions = model.general.tradingInstruction;
            this.postTrade = 0;
            this.showTabs = true;
            this.showAccountsTab = false;
            this.showPortfolioTab = true;
          },
          error => {
            console.error(error);
          });
    }
  }

  /** to validate trade on click of Valdate button  */
  validateTrade(_ValidateTrade: IValidateTrade) {
  // fix for OE-3438 -disabling all the buttons
   this.isDisableAdd = true;
   this.isDisableValidate = true;
   this.isDisableReset = true;
    if (isNaN(_ValidateTrade.tradeShares)) {
      _ValidateTrade.tradeShares = 0;
    }
    if (isNaN(_ValidateTrade.tradePercent)) {
      _ValidateTrade.tradePercent = 0;
    }
    if (isNaN(_ValidateTrade.tradeAmount)) {
      _ValidateTrade.tradeAmount = 0;
    }
    if (isNaN(_ValidateTrade.accountId)) {
      _ValidateTrade.accountId = 0;
    }
    if (isNaN(_ValidateTrade.portfolioId)) {
      _ValidateTrade.portfolioId = 0;
    }
    // eslint-disable-next-line eqeqeq
    if (this.tradeFilterMethod == 'portfolio') {
      _ValidateTrade.portfolioId = this.selectedItem.id;
    // eslint-disable-next-line eqeqeq
    } else if ((this.tradeFilterMethod == 'account') && (this.selectedItem != undefined)) {
      _ValidateTrade.portfolioId = this.selectedItem.portfolioId;
      _ValidateTrade.accountId = this.selectedItem.id;
    }
    // eslint-disable-next-line eqeqeq
    if (this.selectedSecurityId != undefined || this.selectedSecurityId != null) {
      _ValidateTrade.securityId = this.selectedSecurityId;
      _ValidateTrade.symbol = this.tickerSymbol;
    } else {
      _ValidateTrade.securityId = 0;
    }
    // eslint-disable-next-line eqeqeq
    if ((_ValidateTrade.accountId == 0) && (_ValidateTrade.portfolioId == 0)) {
      this.showErrorMessage = true;
      this.errorMessage = 'Missing required parameters accountId or portfolioId';
      return;
    }
    const validateTradeObj = <IValidateTrades>{
      application: 'QuickTrades',
      trades: [_ValidateTrade]
    };
    // this._logger.log(JSON.stringify(_ValidateTrade))
    this._tomService.validateTrade(validateTradeObj)
      .subscribe(model => {
          //  post trade values will be calculated
          this.postTrade = model && model.length && model[0].cashValuePostTrade !== null && model[0].cashValuePostTrade !== undefined ? (model[0].cashValuePostTrade).toFixed(2) : null;
         // fix for OE-3438 -enabling all the buttons after process
          this.isDisableAdd = false;
          this.isDisableValidate = false;
          this.isDisableReset = false;

          model.warningMessage = model[0].error ? model[0].error.map(err => {
            err.errorMessage = _.find(this.tradeOrderErrorMessages, errMsg => errMsg.id === err.errorMessageId).message;
            const errArgs = err.errorMessageArguments.split(';');
            for (let i: number = 0, len = errArgs.length; i < len; i++) {
              // eslint-disable-next-line prefer-template
              err.errorMessage = err.errorMessage.replace('{' + i + '}', errArgs[i]);
            }
            return err.errorMessage;
          }) : [];

          this.warningMessages = model.warningMessage;
          // eslint-disable-next-line eqeqeq
          if (this.warningMessages != undefined && this.warningMessages.length) {
            this.showWarningMessage = true;
            this.showErrorMessage = false;
          }
        },
        error => {
          console.error(error);
          this.errorMessage = error.message;
          this.showErrorMessage = true;
          this.showWarningMessage = false;
          this.isDisableAdd = true;
          this.isDisableReset = false;
        });
  }

  /** save trade on click of Add button  */
  saveTrade(_tradeSave: IValidateTrade) {
    this.showErrorMessage = false;
    this.showWarningMessage = false;
    // fix for OE-3438 -disabling all the buttons
    this.isDisableAdd = true;
    this.isDisableValidate = true;
    this.isDisableReset = true;
    if (isNaN(_tradeSave.portfolioId)) {
      _tradeSave.portfolioId = 0;
    }
    if (isNaN(_tradeSave.accountId)) {
      _tradeSave.accountId = 0;
    }
    if (this.tradeFilterMethod === 'portfolio') {
      // _tradeSave.portfolioId = this.selectedItem.id;
      // eslint-disable-next-line eqeqeq
      if (this.selectedItem != undefined && this.selectedItem.id != undefined) {
        _tradeSave.portfolioId = this.selectedItem.id;
      } else {
        this.showErrorMessage = true;
        this.errorMessage = 'Please select a valid portfolio';
        this.isDisableAdd = false;
        this.isDisableValidate = false;
        this.isDisableReset = false;
        return;
      }
      _tradeSave.accountId = 0;
      _tradeSave.securityId = 0;
      _tradeSave.tradeShares = null;
      _tradeSave.tradePercent = null;
      _tradeSave.tradeAmount = null;
    // eslint-disable-next-line eqeqeq
    } else if (this.tradeFilterMethod == 'account') {
      if (isNaN(_tradeSave.tradeShares)) {
        _tradeSave.tradeShares = null;
      }
      if (isNaN(_tradeSave.tradePercent)) {
        _tradeSave.tradePercent = null;
      }
      if (isNaN(_tradeSave.tradeAmount)) {
        _tradeSave.tradeAmount = null;
      }
      // eslint-disable-next-line eqeqeq
      if (this.selectedItem != undefined && this.selectedItem.id != undefined) {
        _tradeSave.portfolioId = this.selectedItem.portfolioId;
        _tradeSave.accountId = this.selectedItem.id;
      } else {
        this.showErrorMessage = true;
        this.errorMessage = 'Please select a valid account';
        this.isDisableAdd = false;
        this.isDisableValidate = false;
        this.isDisableReset = false;
        return;
      }
      // eslint-disable-next-line eqeqeq
      if (this.selectedSecurityId != undefined || this.selectedSecurityId != null) {
        _tradeSave.securityId = this.selectedSecurityId;
      } else {
        // fix for OE-2051 to avoid error if no ticker selected for buy or sell action
        // eslint-disable-next-line eqeqeq
        if (_tradeSave.action == ActionType.BUY || _tradeSave.action == ActionType.Sell) {
          this.showErrorMessage = true;
          this.errorMessage = 'Please select product or ticker to buy/sell';
          this.isDisableAdd = false;
          this.isDisableValidate = false;
          this.isDisableReset = false;
          return;
        } else {
          this.showErrorMessage = false;
          _tradeSave.securityId = 0;
        }
      }
      // eslint-disable-next-line eqeqeq
      if ((_tradeSave.action == ActionType.Buy_Rebalance || _tradeSave.action == ActionType.Sell_Rebalance) && !_tradeSave.tradeAmount) {    // Quick trade:- Option Sell Rebalance for an account is not working. (if Dollar amount is not entered)
        this.showErrorMessage = true;
        this.isDisableAdd = false;
        this.isDisableValidate = false;
        this.isDisableReset = false;
        this.errorMessage = 'Please enter the dollar amount';
        return;
      }
      // OE-4474 added real time price error warning message in case of quick trade.
      // eslint-disable-next-line eqeqeq
      if (_tradeSave.action == ActionType.BUY || _tradeSave.action == ActionType.Sell) {
        if (this.realTimePriceError) {
          _tradeSave.realTimePriceError = true;
        } else {
          _tradeSave.realTimePriceError = false;
        }
      }
    }

    let obsSave;

    // Send Immediately, Liquidate, and Rebalance trades are sent to v1.  All others are sent to v2.
    // eslint-disable-next-line eqeqeq
    if (_tradeSave.isSendImmediately || !(+_tradeSave.action == ActionType.BUY || +_tradeSave.action == ActionType.Sell)) {
      const saveTradesv1 = <IQuickTradeData>{
        accountId: _tradeSave.accountId,
        portfolioId: _tradeSave.portfolioId,
        actionId: _tradeSave.action,
        securityId: _tradeSave.securityId,
        dollarAmount: _tradeSave.tradeAmount,
        quantity: _tradeSave.tradeShares,
        percentage: _tradeSave.tradePercent,
        isSendImmediately: _tradeSave.isSendImmediately,
        realTimePriceError: _tradeSave.realTimePriceError,
        tradeToolSelection : this._tradeToolsService.getTradeToolSelectionMethod(this.tradeFilterMethod),
        tradeInstanceType : TradeInstanceType.QuickTrade,
        tradeInstanceSubType : TradeInstanceSubType.QuickTrade
      };
      obsSave = this._tomService.saveTrade_v1(saveTradesv1);
    } else {
      const saveTrades = <IValidateTrades>{
        application: 'QuickTrades',
        trades: [_tradeSave]
      };
      saveTrades.tradeToolSelection = this._tradeToolsService.getTradeToolSelectionMethod(this.tradeFilterMethod);
      saveTrades.tradeInstanceType = TradeInstanceType.QuickTrade;
      saveTrades.tradeInstanceSubType = TradeInstanceSubType.QuickTrade;

      obsSave = this._tomService.saveTrade(saveTrades);
    }

    obsSave.subscribe(model => {
          /**resetting screen back to normal */
          if (model.message && !model.instanceId) {
            this.errorMessage = model.message;
            this.showErrorMessage = true;
          } else {
            this.showErrorMessage = false;
            this.resetPartialForm();
          }
        },
        error => {
          console.error(error);
          this.errorMessage = error.message;
          this.showErrorMessage = true;
          this.isDisableAdd = true;
        });
  }

  /** get assest tab details for quicktrade */
  getAssetsforAccount(id) {
    // eslint-disable-next-line eqeqeq
    if (this.tradeFilterMethod == 'account') {
      this._tomService.getAssetsforAccount(id)
        .subscribe(model => {
            this.accountAssets = model;
          },
          error => {
            console.error(error);
          });
    } else {
      this._tomService.getAssetsforPortfolio(id)
        .subscribe(model => {
            this.accountAssets = model;
          },
          error => {
            console.error(error);
          });
    }
  }

  /** get price tab details for selected Security */
  getPriceDetails(id) {
    this._tomService.getPrice(id)
      .subscribe(model => {
          this.isRealTimeQuote = model.isRealTimeQuote;
          if (this.isRealTimeQuote) {
            this.LastPrice = model.last;
            this.asof = moment.utc(model.date).local().format('YYYY-MM-DD HH:mm:ss');
            // eslint-disable-next-line prefer-template
            console.log('Date Received :' + model.date + ' Date after conversion ' + this.asof);
            this.previousClose = model.marketClosePrice;
            this.change = model.changeFromMarketClose;
            this.changePercent = model.percentChangeFromMarketClose;
            this.bid = model.bid;
            this.bidSize = model.bidQuantity;
            this.ask = model.ask;
            this.askSize = model.askQuantity;
            this.dayHigh = model.high;
            this.dayLow = model.low;
            this.volume = model.volume;
            this.open = model.open;
            this.realTimePriceError = false;
          } else {
            this.realTimePriceError = model.realTimePriceError;
            this.LastPrice = model.price;
            this.asof = model.priceDate ? moment.utc(model.priceDate).local().format('YYYY-MM-DD HH:mm:ss') :
              moment.utc(model.date).local().format('YYYY-MM-DD HH:mm:ss');

            // eslint-disable-next-line prefer-template
            console.log('Date Received :' + model.priceDate + ' Date after conversion ' + this.asof);
          }
        },
        error => {
          console.error(error);
        });
  }

  /** setting defaults to ui visible */
  setDefaults() {
    if (this.tradeFilterMethod === 'portfolio') {
      this.quickTradeData.action = ActionType.Liquidate;
    } else {
      this.quickTradeData.action = ActionType.BUY;
    }
    this.quickTradeData.isSendImmediately = false;
    this.showSellSecuritySearch = false;
    this.placeHolderAction = 'Buy';
    this.showErrorMessage = false;
    this.showWarningMessage = false;
    this.dollarsPlaceHolder = 'Amount to buy';
    this.placeHolderAction = 'Buy';
    this.placeHolderShares = 'Quantity to buy';
    this.isTradeActionSell = false;
    if (this.tradeFilterMethod === 'portfolio') {
      this.showBuySecuritySearch = false;
      this.showShares = false;
      this.showDollar = false;
      this.showValidate = false;
    } else {
      this.showBuySecuritySearch = true;
      this.showShares = true;
      this.showDollar = true;
      this.showValidate = true;
    }
  }

  /** when selecting dollars to trade need to disable shares input */
  disableShares(val) {
    this.isDisableShare = true;
    this.isDisableDollor = false;
    this.quickTradeData.tradeShares = null;
    this.isDisablePercent = true;
    this.quickTradeData.tradePercent = null;
    this.isInValidPercent = false;
    setTimeout(() => this.dollarsInput.input.nativeElement.focus(), 0);
  }

  /** when selecting share to trade need to disable dollar input */
  disableDollor(val) {
    this.isDisableShare = false;
    this.isDisableDollor = true;
    this.quickTradeData.tradeAmount = null;
    this.isDisablePercent = true;
    this.quickTradeData.tradePercent = null;
    this.isInValidPercent = false;
    setTimeout(() => this.sharesInput.input.nativeElement.focus(), 0);
  }

  disableOthers(val) {
    this.isDisablePercent = false;
    this.isDisableShare = true;
    this.isDisableDollor = true;
    this.quickTradeData.tradeAmount = null;
    this.quickTradeData.tradeShares = null;
    setTimeout(() => this.percentInput.input.nativeElement.focus(), 0);
  }

  /**
   * Validate the percentage input.  Must be greater than 0 and less than or equal to 100.
   * @param value
   */
  validatePercentValue(value) {
    this.isInValidPercent = false;
    this.isTotalPercentExceed = false;
    // eslint-disable-next-line eqeqeq
    if (value === null || value === undefined) {
      this.isInValidPercent = true;
      return false;
    }
    value = value.toString();
    const pattern = /^\d+(\.\d{1,2})?$/;
    if (!value.match(pattern)) {
      this.isInValidPercent = true;
      return false;
    }
    /** Value should be greater than 0 and less than or equal to 100 */
    if (+value > 100) {
      this.quickTradeData.tradePercent = 100;
    }
    this.isInValidPercent = +value <= 0;

  }

  onSecurityChange(evt, value) {
    // Don't reset the selected security if the Enter key is pressed, otherwise the security will be undefined in the onSelect event
    if (evt.key !== 'Enter' && evt.keyCode !== 13) {
      this.selectedSecurityId = undefined;
    }
  }

  // Enable and disable the add or validate button according to analytics status.
  disableAndEnableButtonByAnalyticsStatus(event: IAnalyticsNotification, actionId = ActionType.BUY, getAccountInfo: boolean) {
    let analyticsDetail = { isDone: false, havePortfolio: false, portfolio: null };
    const isActionBuyOrSell = (actionId === ActionType.BUY || actionId === ActionType.Sell);
    if (getAccountInfo || !isActionBuyOrSell) {
      analyticsDetail = this.getAnalyticsDetail(event);
    }

    if (isActionBuyOrSell) {
      this.isDisableValidate = false;
      this.isDisableAdd = false;
    } else if (analyticsDetail.isDone) {
        const portfolio = analyticsDetail.portfolio;
        this.setDataAfterAnalytics(event, portfolio);
    } else if (analyticsDetail.havePortfolio) {
      this.isDisableValidate = true;
      this.isDisableAdd = true;
    }

    if (getAccountInfo && analyticsDetail.isDone) {
      this.getAccountDetails(this.selectedItem.id);
    }
  }

  getAnalyticsDetail(event: IAnalyticsNotification) {
    const analyticsDetail = { isDone: false, havePortfolio: false, portfolio: null };
    const needAnalytics = AnalyticsHelper.getAnalyticsStatus(event.portfolioFlags);
    if (event && this.selectedItem) {
      const portfolio = event.portfolioFlags ? event.portfolioFlags.find(p => p.portfolioId === (this.selectedItem.portfolioId ?? this.selectedItem.id)) : null;
      if (portfolio) {
        analyticsDetail.havePortfolio = true;
        if (Number(needAnalytics) === 0 || event.isAnalyticsCompleted || !!event.errorMessage || this.selectedItem.isDone) {
          analyticsDetail.isDone = true;
          analyticsDetail.portfolio = portfolio;
        }
      }
    }
    return analyticsDetail;
  }

  setDataAfterAnalytics(event: IAnalyticsNotification, portfolio: any): void {
    const analyticsStatus = AnalyticsHelper.getAnalyticsStatus(event.portfolioFlags);
    const failedReason = AnalyticsHelper.getRejectReason(event.portfolioFlags, analyticsStatus);
    this.isDisableValidate = false;
    this.isDisableAdd = false;
    this.selectedItem.isDone = event.isAnalyticsCompleted;
    this.selectedItem.needAnalytics = portfolio ? portfolio.needAnalytics : analyticsStatus;
    this.selectedItem.failedReason = portfolio ? portfolio.failedReason : failedReason;
  }
}
